import { assertResponseSuccess } from 'providers/functions';
import { CosmosCreateProvidersParams, httpClient } from '../CosmosRAProvidersContext';
import { AppItem, SkinItem } from 'utility/types/dataProvider';

export const createTemplateInfoProvider = ({
  apiUrl,
  authToken,
  defaultSite,
  stringifyOptions,
  queryClient,
}: CosmosCreateProvidersParams) => ({
  // Get installed apps for a template library
  getInstalledApps: async (
    library: string,
    limit: number = 25,
    page: number = 0,
    sort: string = 'title',
  ): Promise<AppItem[]> => {
    try {
      const url = `${apiUrl}/core/template/info/${library}/app/?limit=${limit}&page=${page}&sort=${sort}`;

      const { json, status } = await httpClient({
        url: url,
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      return json.data;
    } catch (error) {
      console.error('[dataProvider] getInstalledApps() error:' + error?.toString());
      throw error;
    }
  },

  // Get installed URL skins for an app
  getInstalledSkins: async (
    library: string,
    app: string,
    limit: number = 25,
    page: number = 0,
    sort: string = 'title',
  ): Promise<SkinItem[]> => {
    try {
      const url = `${apiUrl}/core/template/info/${library}/skin/${app}/?limit=${limit}&page=${page}&sort=${sort}`;

      const { json, status } = await httpClient({
        url: url,
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      return json.data;
    } catch (error) {
      console.error('[dataProvider] getInstalledSkins() error:' + error?.toString());
      throw error;
    }
  },

  // Get information about a URL skin
  getSkinInfo: async (
    library: string,
    app: string,
    package_name: string,
  ): Promise<SkinItem> => {
    try {
      const url = `${apiUrl}/core/template/info/${library}/skin/${app}/${package_name}/`;

      const { json, status } = await httpClient({
        url: url,
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      return json.data;
    } catch (error) {
      console.error('[dataProvider] getSkinInfo() error:' + error?.toString());
      throw error;
    }
  },
});

import { assertResponseSuccess } from 'providers/functions';
import { CosmosCreateProvidersParams, httpClient } from '../CosmosRAProvidersContext';
import { BlockSearchData } from 'utility/types/dataProvider';

export const createBlockLibraryProvider = ({
  apiUrl,
  authToken,
  defaultSite,
  stringifyOptions,
  queryClient,
}: CosmosCreateProvidersParams) => ({
  // Search blocks for a URL map
  getBlockSearch: async (
    map_id: string,
    query: string = '',
    limit: number = 10,
    page: number = 0,
  ): Promise<BlockSearchData> => {
    try {
      const queryString = new URLSearchParams({
        query,
        limit: limit.toString(),
        page: page.toString(),
      }).toString();

      const url = `${apiUrl}/core/block/${map_id}/?${queryString}`;

      const { json, status } = await httpClient({
        url: url,
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      return json.data as BlockSearchData;
    } catch (error) {
      console.error('[dataProvider] searchBlocks() error:' + error?.toString());
      throw error;
    }
  },
});

import { stringify } from 'query-string';
import { TGetWidgetDataByIdParams } from 'utility/types/dataProvider';
import { CosmosCreateProvidersParams, httpClient } from '../CosmosRAProvidersContext';

// -----------------------------------------------
// ----------- DASHBOARDS PROVIDER ---------------
// -----------------------------------------------
export const createDashboardsProvider = ({
  apiUrl,
  authToken,
  defaultSite,
  stringifyOptions,
  queryClient,
}: CosmosCreateProvidersParams) => ({
  // Dashboards.
  getDashboardById: async ({ id }: { id: string }) => {
    const url: string = `${apiUrl}/insights/dashboard/${id}/`;

    try {
      const { json } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      return {
        status: json.status,
        data: json.data,
      };
    } catch (error) {
      console.error(error);
    }
  },

  getWidgetDataById: async ({ id, assetUUID }: TGetWidgetDataByIdParams) => {
    try {
      let url: string = `${apiUrl}/insights/widget/${id}/data/`;

      if (!!assetUUID) {
        const query = stringify({ assetUUID });
        url += `?${query}`;
      }

      const { json } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      return json.data;
    } catch (error) {
      console.error(
        `[dataProvider] getWidgetDataById() - (Widget ID): ${id} error: ${error?.toString()}`,
      );
      throw error;
    }
  },

  // Returns all RSS widgets for the current dashboard.
  // What is returned has to be mapped to the dashboard layout property.
  getAllDashboardRssWidgetModules: async (appId: string, widgetId: string) => {
    try {
      const moduleUrl = new URL(`${apiUrl}/insights/module/`);
      moduleUrl.searchParams.append('app', appId);
      moduleUrl.searchParams.append('module', 'rss');
      moduleUrl.searchParams.append('limit', '5');

      const { json } = await httpClient({
        url: moduleUrl.toString(),
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      return json.data;
    } catch (error) {
      console.error(
        `[dataProvider] getAllDashboardRssWidgets() - (ID-Node): ${widgetId} error:` +
          error?.toString(),
      );
      throw error;
    }
  },
});

import { assertResponseSuccess } from 'providers/functions';
import { CosmosCreateProvidersParams, httpClient } from '../CosmosRAProvidersContext';

import { UserWallet } from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ USERWALLETS PROVIDER -------------
// -----------------------------------------------
export const createUserWalletsProvider = ({
  apiUrl,
  authToken,
  defaultSite,
  stringifyOptions,
  queryClient,
}: CosmosCreateProvidersParams) => ({
  getUserWalletData: async (
    uuid: string,
    { nextToken, limit }: { nextToken?: string | null; limit: number },
  ): Promise<UserWallet> => {
    try {
      const url = new URL(`${apiUrl}/pay/wallet/${uuid}/search/`);
      if (nextToken) {
        url.searchParams.append('page', nextToken);
      }
      url.searchParams.append('limit', limit.toString());

      const urlString = url.toString();

      const { json, status } = await httpClient({
        url: urlString,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: UserWallet = json.data;
      return result;
    } catch (error) {
      console.error('[dataProvider] getUserWallet() error:' + error?.toString());
      throw error;
    }
  },

  addPaymentMethod: async (uuid: string, params: any): Promise<UserWallet> => {
    try {
      const url = `${apiUrl}/pay/wallet/${uuid}/payment/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'POST',
          body: JSON.stringify(params),
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: UserWallet = json.data;
      return result;
    } catch (error) {
      console.error('[dataProvider] addPaymentMethod() error:' + error?.toString());
      throw error;
    }
  },

  removePaymentMethod: async (uuid: string, paymentId: string): Promise<void> => {
    try {
      const url = `${apiUrl}/pay/wallet/${uuid}/payment/${paymentId}/`;

      const response = await httpClient({
        url: url,
        options: {
          method: 'DELETE',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(response?.json, response?.status);

      const result = Promise.resolve();
      return result;
    } catch (error) {
      console.error('[dataProvider] removePaymentMethod() error:' + error?.toString());
      throw error;
    }
  },

  editPaymentMethod: async (
    uuid: string,
    paymentId: string,
    params: any,
  ): Promise<void> => {
    try {
      const url = `${apiUrl}/pay/wallet/${uuid}/payment/${paymentId}/`;

      const response = await httpClient({
        url: url,
        options: {
          method: 'PUT',
          body: JSON.stringify(params),
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(response?.json, response?.status);

      const result = Promise.resolve();
      return result;
    } catch (error) {
      console.error('[dataProvider] editPaymentMethod() error:' + error?.toString());
      throw error;
    }
  },
});

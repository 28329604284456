import { assertResponseSuccess } from 'providers/functions';
import { CosmosCreateProvidersParams, httpClient } from '../CosmosRAProvidersContext';

import {
  AnalysisRequest,
  AnalysisResponse,
  BinaryUploadParams,
  GetEditorialConfigResult,
  KeywordSuggestion,
} from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ EDITORIAL PROVIDER ---------------
// -----------------------------------------------
export const createEditorialProvider = ({
  apiUrl,
  authToken,
  defaultSite,
  stringifyOptions,
  queryClient,
}: CosmosCreateProvidersParams) => ({
  // Get editorial config
  binaryUpload: async (resource: string, params: BinaryUploadParams) => {
    try {
      const url = `${apiUrl}${resource}/`;
      const formData = new FormData();
      if (params.file) {
        formData.append('file', params.file);
      }

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'POST',
          body: formData,
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const formattedResult = {
        data: { ...json },
      };
      return formattedResult;
    } catch (error) {
      console.error('[dataProvider] binaryUpload() error:' + error?.toString());
      throw error;
    }
  },

  binaryConvert: async (resource: string, params: any) => {
    try {
      const url = `${apiUrl}${resource}/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'POST',
          body: JSON.stringify(params),
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const formattedResult = {
        data: { ...json },
      };
      return formattedResult;
    } catch (error) {
      console.error('[dataProvider] binaryConvert() error:' + error?.toString());
      throw error;
    }
  },

  // Get editorial config
  getEditorialConfig: async (): Promise<GetEditorialConfigResult> => {
    try {
      const url = `${apiUrl}/editorial/config/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const editorialConfig: GetEditorialConfigResult = json;
      return editorialConfig;
    } catch (error) {
      console.error('[dataProvider] getEditorialConfig() error:' + error?.toString());
      throw error;
    }
  },

  // Editorial semantic analysis
  semanticAnalysis: async ({
    request,
  }: {
    request: AnalysisRequest;
  }): Promise<AnalysisResponse> => {
    try {
      const url = `${apiUrl}/editorial/semantic/analyze/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'POST',
          body: JSON.stringify(request),
          headers: { 'Content-Type': 'application/json' },
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const responseData = json;
      const keywords: KeywordSuggestion[] = responseData.data.keywords.map(
        (keywordData: any) => ({
          tag: keywordData.tag,
          score: keywordData.score,
        }),
      );
      const response: AnalysisResponse = {
        status: 'success',
        data: {
          keywords: keywords,
          categories: responseData.data.categories || [],
          iptc: responseData.data.iptc || [],
          iab: responseData.data.iab || [],
        },
      };
      return response;
    } catch (error) {
      console.error('[dataProvider] analyzeData() error:', error?.toString());
      throw error;
    }
  },
});

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);
dayjs.extend(utc);

/**
 * Utility Constants
 */

// https://html.spec.whatwg.org/multipage/input.html#input.email.attrs.value.multiple
/** https://emailregex.com/index.html */
export const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Unicode characters
export const emdash = '\u2014';
export const endash = '\u2013';
export const dash = '\u2010';
export const ellipsis = '\u2026';
export const verticalEllipsis = '\u22EE';
export const nbsp = '\u00A0';
export const moneyWingsEmoji = '\u{1F4B8}'; // or \uD83D\uDCB8
export const lightbulbEmoji = '\u{1F4A1}'; // or \uD83D\uDCA1

/**
 * Date Formats: https://day.js.org/docs/en/display/format
 */

// Sep 23, 2024
export const MMM_DD_YYYY: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: '2-digit',
  year: 'numeric',
};

// 12/31/21
export const MM_DD_YY: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
};

// Friday, December 31, 2021
export const WEEKDAY_MMMM_DD_YYYY: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  month: 'long',
  day: '2-digit',
  year: 'numeric',
};

// April 2022
export const MMMM_YYYY: Intl.DateTimeFormatOptions = {
  month: 'long',
  year: 'numeric',
};

// Date/Time Constants generally for mui datepickers
export const absMaxDateTime = dayjs('9999-12-31T23:59:59.999Z').utc(); // note: can possibly lag datepickers open
export const absMinDateTime = dayjs('100-01-01T00:00:00.000Z').utc();
export const safeMaxDateTime = dayjs('2500-12-31T23:59:59.999Z').utc(); // more reasonable max / mins
export const safeMinDateTime = dayjs('1500-01-01T00:00:00.000Z').utc();

// cache time constants
export const fiveMinMs = 300000;

// 32-bit min-max values for various applications 2,147,483,647 / -2,147,483,648
export const int32Max = 2147483647;
export const int32Min = -2147483648;

// lowercase - check if your input props name / autocomplete value matches any of the following
// then you should mark it as data-private for logrocket masking purposes.
// reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
export const PRIVATE_FIELDS = [
  'shipping',
  'billing',
  'home',
  'work',
  'mobile',
  'fax',
  'page',
  'tel',
  'tel-country-code',
  'tel-national',
  'tel-area-code',
  'tel-local',
  'tel-extension',
  'email',
  'impp',
  'name',
  'honorific-prefix',
  'given-name',
  'additional-name',
  'family-name',
  'honorific-suffix',
  'nickname',
  'username',
  'new-password',
  'current-password',
  'one-time-code',
  'organization-title',
  'organization',
  'street-address',
  'address',
  'address-line1',
  'address-line2',
  'address-line3',
  'address-level1',
  'address-level2',
  'address-level3',
  'address-level4',
  'country',
  'country-name',
  'postal-code',
  'cc-name',
  'cc-given-name',
  'cc-additional-name',
  'cc-family-name',
  'cc-number',
  'cc-exp',
  'cc-exp-month',
  'cc-exp-year',
  'cc-csc',
  'cc-type',
  'bday',
  'bday-day',
  'bday-month',
  'bday-year',
  'sex',
  'photo',
  'webauthn',
] as const;

/** Accepted File Upload Input MIME Types Array for File Asset + Inline File */
export const acceptedFileTypes: string[] = [
  // --- Archive file types ---
  'application/zip', // IANA RFC official .zip
  'application/x-zip-compressed', // windows .zip type
  'application/zip-compressed', // non-standard .zip used by some programs

  // NOTE: Some unaccepted zip types (bz, bz2, 7z, rar) will misclassify themselves as an accepted type
  // In this case the client cant reject instantly, but the backend will respond with an invalid type upon upload completion.
  // --- Individual file types ---
  'application/pdf', // .pdf
  'application/msword', // .doc, .dot, .wiz
  'application/dot', // .dot word template
  'application/vnd.ms-powerpoint', // .ppt, .pot, .pps, .ppa
  'application/vnd.ms-excel', // .xla, .xlb, .xlc, .xlm, .xls, .xlt, .xlw
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
];

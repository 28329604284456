import { assertResponseSuccess } from 'providers/functions';
import {
  CreateAccessRuleParams,
  CreateAccessRuleResult,
  DeleteAccessRuleParams,
  DeleteAccessRuleResult,
  GetAccessCriteriaParams,
  GetAccessCriteriaResult,
  GetAccessMethodsParams,
  GetAccessMethodsResult,
  GetAccessOffersParams,
  GetAccessOffersResult,
  GetAccessRuleByIdParams,
  GetAccessRuleByIdResult,
  GetAccessRulesParams,
  GetAccessRulesResult,
  UpdateAccessRuleParams,
  UpdateAccessRuleResult,
} from 'utility/types/dataProvider';
import { CosmosCreateProvidersParams, httpClient } from '../CosmosRAProvidersContext';

// -----------------------------------------------
// -------------- Access Control -----------------
// -----------------------------------------------
export const createAccessControlProvider = ({
  apiUrl,
  authToken,
  defaultSite,
  stringifyOptions,
  queryClient,
}: CosmosCreateProvidersParams) => ({
  // get /access/criteria/
  getAccessCriteria: async (
    params?: GetAccessCriteriaParams,
  ): Promise<GetAccessCriteriaResult> => {
    const { query, page, limit = 99 } = params || {};

    try {
      const url = new URL(
        `${apiUrl}/access/criteria/?` +
          new URLSearchParams({
            ...(query && { query: query }),
            ...(page && { page: String(page) }),
            ...(limit && { limit: String(limit) }),
          }),
      ).toString();

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetAccessCriteriaResult = {
        status: json?.status || status,
        data: json?.data,
        ...json,
      };

      return result;
    } catch (error) {
      console.error(
        '[getAccessCriteria] getAccessCriteria() error: ' + error?.toString(),
      );
      throw error;
    }
  },

  // get /access/offers/
  getAccessOffers: async (
    params?: GetAccessOffersParams,
  ): Promise<GetAccessOffersResult> => {
    const { query, page, limit = 99 } = params || {};

    try {
      const url = new URL(
        `${apiUrl}/access/offers/?` +
          new URLSearchParams({
            ...(query && { query: query }),
            ...(page && { page: String(page) }),
            ...(limit && { limit: String(limit) }),
          }),
      ).toString();

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetAccessOffersResult = {
        status: json?.status || status,
        data: json?.data,
        ...json,
      };

      return result;
    } catch (error) {
      console.error('[getAccessOffers] getAccessOffers() error: ' + error?.toString());
      throw error;
    }
  },

  // get /access/methods/
  getAccessMethods: async (
    params?: GetAccessMethodsParams,
  ): Promise<GetAccessMethodsResult> => {
    const { query, page, limit = 99 } = params || {};

    try {
      const url = new URL(
        `${apiUrl}/access/methods/?` +
          new URLSearchParams({
            ...(query && { query: query }),
            ...(page && { page: String(page) }),
            ...(limit && { limit: String(limit) }),
          }),
      ).toString();

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetAccessMethodsResult = {
        status: json?.status || status,
        data: json?.data,
        ...json,
      };

      return result;
    } catch (error) {
      console.error('[getAccessMethods] getAccessMethods() error: ' + error?.toString());
      throw error;
    }
  },

  // get /access/rules/
  getAccessRules: async (
    params?: GetAccessRulesParams,
  ): Promise<GetAccessRulesResult> => {
    const { type, query, is_enabled, dir, sort, page, limit = '99' } = params || {};

    try {
      const url = new URL(
        `${apiUrl}/access/rules/?` +
          new URLSearchParams({
            ...(type && { type: type }),
            ...(query && { query: query }),
            ...(is_enabled && { is_enabled: String(is_enabled) }),
            ...(dir && { dir: dir }),
            ...(sort && { sort: sort }),
            ...(page && { page: page }),
            ...(limit && { limit: limit }),
          }),
      ).toString();

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetAccessRulesResult = {
        status: json?.status || status,
        data: json?.data,
        ...json,
      };

      return result;
    } catch (error) {
      console.error('[getAccessRules] getAccessRules() error: ' + error?.toString());
      throw error;
    }
  },

  // get /access/rules/{id}/
  getAccessRuleById: async (
    params: GetAccessRuleByIdParams,
  ): Promise<GetAccessRuleByIdResult> => {
    try {
      const { id } = params;

      if (!id) {
        throw new Error('Missing required parameter!');
      }

      const url = new URL(`${apiUrl}/access/rules/${id}/`).toString();

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });
      await assertResponseSuccess(json, status);

      const result: GetAccessRuleByIdResult = {
        status: json?.status || status,
        data: json?.data,
        ...json,
      };

      return result;
    } catch (error) {
      console.error(
        '[getAccessRuleById] getAccessRuleById() error: ' + error?.toString(),
      );
      throw error;
    }
  },

  // post /access/rules/ (create)
  createAccessRule: async (
    params: CreateAccessRuleParams,
  ): Promise<CreateAccessRuleResult> => {
    try {
      const url = new URL(`${apiUrl}/access/rules/`).toString();

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params),
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: CreateAccessRuleResult = {
        status: json?.status || status,
        data: json?.data,
        ...json,
      };

      return result;
    } catch (error) {
      console.error('[createAccessRule] createAccessRule() error: ' + error?.toString());
      throw error;
    }
  },

  // put /access/rules/{id}/ (update rule)
  updateAccessRule: async (
    params: UpdateAccessRuleParams,
  ): Promise<UpdateAccessRuleResult> => {
    try {
      const { id, ...body } = params;

      if (!id) {
        throw new Error('Missing required parameter: id');
      }

      const url = new URL(`${apiUrl}/access/rules/${id}/`).toString();

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: UpdateAccessRuleResult = {
        status: json?.status || status,
        data: json?.data,
        ...json,
      };

      return result;
    } catch (error) {
      console.error('[updateAccessRule] updateAccessRule() error: ' + error?.toString());
      throw error;
    }
  },

  // delete /access/rules/{id}/ (delete rule)
  deleteAccessRule: async (
    params: DeleteAccessRuleParams,
  ): Promise<DeleteAccessRuleResult> => {
    try {
      const { id } = params;

      if (!id) {
        throw new Error('Missing required parameter: id');
      }
      const url = new URL(`${apiUrl}/access/rules/${id}/`).toString();

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'DELETE',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      // Status code 204 if successful, not in json.status
      await assertResponseSuccess(json, status);

      const result: DeleteAccessRuleResult = {
        status: json?.status || status,
        data: json?.data,
        ...json,
      };

      return result;
    } catch (error) {
      console.error('[deleteAccessRule] deleteAccessRule() error: ' + error?.toString());
      throw error;
    }
  },
});

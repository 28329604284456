import { QueryClient, UseQueryOptions } from 'react-query';
import { fiveMinMs } from 'utility/constants';

export const queryClient = new QueryClient();

// Utility type / const to spread common useQuery hooks
type SubsetQueryOptions = Pick<
  UseQueryOptions<any, any>,
  'cacheTime' | 'staleTime' | 'refetchOnMount' | 'refetchOnWindowFocus'
>;

export const commonQueryOptions: SubsetQueryOptions = {
  cacheTime: fiveMinMs,
  staleTime: fiveMinMs,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

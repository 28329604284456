import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  PropsWithChildren,
  memo,
} from 'react';
import { useLocation } from 'react-router-dom';

interface LocationState {
  /** Array of the last `50` `location = useLocation()` entries  */
  history: ReturnType<typeof useLocation>[];
  /** Undefined if there is no history (e.g. first page, directly navigated to) */
  lastPage: ReturnType<typeof useLocation> | undefined;
}

const CosmosHistoryContext = createContext<LocationState | undefined>(undefined);

/**
 * Stores the last 50 `location = useLocation()` entries in an array, in context.
 * NOTE: The last item in the array is the current location.
 * @example const { history, lastPage } = useCosmosHistory();
 *
 **/
export const useCosmosHistory = () => {
  const context = useContext(CosmosHistoryContext);
  if (!context) {
    throw new Error('useLocationHistory must be used within a LocationHistoryProvider');
  }
  return context;
};

export const CosmosHistoryProvider = memo(({ children }: PropsWithChildren) => {
  const [history, setHistory] = useState<ReturnType<typeof useLocation>[]>([]);
  const location = useLocation();

  useEffect(() => {
    // Keep the last 50 entries, should be enough and still be performant.
    setHistory((prevHistory) => [...prevHistory.slice(-49), location]);
  }, [location]);

  const contextValue = useMemo(() => {
    return {
      history,
      lastPage: history[history.length - 2],
    };
  }, [history]);

  return (
    <CosmosHistoryContext.Provider value={contextValue}>
      {children}
    </CosmosHistoryContext.Provider>
  );
});
CosmosHistoryProvider.displayName = 'CosmosHistoryProvider';

import gravatarUrl from 'gravatar-url';
import { type StringifyOptions } from 'query-string';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { AuthProvider, GetManyResult, UserIdentity, fetchUtils } from 'react-admin';
import { useAuth } from 'react-oidc-context';
import { QueryClient, useQueryClient } from 'react-query';
import { BloxCustomDataProviderType } from 'utility/types/dataProvider';
import { v4 as uuidv4 } from 'uuid';
import BloxLoadingSplash from '../../components/generic/BloxLoadingSplash';
import {
  assertResponseSuccess,
  determineUniqueId,
  getResourceDetails,
  relatedContent,
  stripTOC,
} from '../functions';
import { reauthDialogStateExpired, useCosmosAuthEnv } from './CosmosAuthEnvContext';
import { useCosmos } from './CosmosContext';
import { createAccessControlProvider } from './extensions/AccessControl';
import { createArtificalIntelligenceProvider } from './extensions/ArtificalIntelligence';
import { createBlockLayoutProvider } from './extensions/BlockLayout';
import { createBlockLibraryProvider } from './extensions/BlockLibrary';
import { createDashboardsProvider } from './extensions/Dashboards';
import { createEditorialProvider } from './extensions/Editorial';
import { createExperimentsProvider } from './extensions/Experiments';
import { createPreviewsProvider } from './extensions/Previews';
import { createSavedSearchesProvider } from './extensions/SavedSearches';
import { createSessionsProvider } from './extensions/Sessions';
import { createSiteTaxonomyProvider } from './extensions/SiteTaxonomy';
import { createSubscriptionsProvider } from './extensions/Subscriptions';
import { createTemplateInfoProvider } from './extensions/TemplateInfo';
import { createUrlMapProvider } from './extensions/UrlMap';
import { createUsersProvider } from './extensions/Users';
import { createUserWalletsProvider } from './extensions/UserWallets';
import { createWorkflowsProvider } from './extensions/Workflows';
import { useLocation } from 'react-router';

type FetchUtilsOptions = fetchUtils.Options;
export interface HttpClientParams {
  url?: string;
  options?: FetchUtilsOptions;
  authToken?: string;
  defaultSite?: string;
}

export const stringifyOptions: StringifyOptions = { arrayFormat: 'bracket' };

export const httpClient = ({
  url,
  options,
  authToken,
  defaultSite,
}: HttpClientParams) => {
  try {
    if (!url || !authToken || !defaultSite) {
      throw new Error('[httpClient] is missing required paramaters. Wont make request.');
    }

    const headers = new Headers({
      Accept: 'application/json',
      Authorization: authToken, // "Bearer <token>"
      'X-Blox-Domain': defaultSite, // Active site/domain for the user.
      'X-Request-Id': uuidv4(), // Add a unique request ID for tracking
      ...options?.headers, // include any additional headers passed to options
    });

    const finalOptions: FetchUtilsOptions = {
      ...options,
      headers: headers,
    };

    return fetchUtils.fetchJson(url, finalOptions);
  } catch (error) {
    console.error('[httpClient] error:' + error?.toString());
    throw error;
  }
};

export interface CosmosCreateProvidersParams {
  apiUrl?: string;
  authToken?: string;
  defaultSite?: string;
  queryClient?: QueryClient;
  stringifyOptions?: StringifyOptions;
}

export interface CosmosDataProviderContextType {
  dataProvider: BloxCustomDataProviderType;
  authProvider: AuthProvider;
}

export const CosmosRAProvidersContext = createContext<
  CosmosDataProviderContextType | undefined
>(undefined);

export const useCosmosRAProviders = () => {
  const context = useContext(CosmosRAProvidersContext);
  if (!context) {
    throw new Error(
      'useCosmosRAProviders must be used within a CosmosRAProvidersContext!',
    );
  }
  return context;
};

/** Builds the Auth Provider and Data Provider for React-Admin **/
export const CosmosRAProviders = ({ children }: PropsWithChildren) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const location = useLocation();
  const { envState, reauthDialog, setReauthDialog, authLogin, authLogout } =
    useCosmosAuthEnv();
  const { defaultSite, apiUrl, authToken } = useCosmos();

  const contextValue = useMemo(() => {
    if (
      !auth ||
      !apiUrl ||
      !authToken ||
      !defaultSite ||
      !queryClient ||
      !envState ||
      !envState?.OIDC_SERVER_URL ||
      !stringifyOptions ||
      !location ||
      !setReauthDialog ||
      !authLogin ||
      !authLogout
    ) {
      return undefined;
    }

    const createProviderParams: CosmosCreateProvidersParams = {
      apiUrl: apiUrl,
      authToken: authToken,
      defaultSite: defaultSite,
      queryClient: queryClient,
      stringifyOptions: stringifyOptions,
    };
    const artificalIntelligenceProvider =
      createArtificalIntelligenceProvider(createProviderParams);
    const blockLayoutProvider = createBlockLayoutProvider(createProviderParams);
    const blockLibraryProvider = createBlockLibraryProvider(createProviderParams);
    const dashboardsProvider = createDashboardsProvider(createProviderParams);
    const editorialProvider = createEditorialProvider(createProviderParams);
    const experimentsProvider = createExperimentsProvider(createProviderParams);
    const previewsProvider = createPreviewsProvider(createProviderParams);
    const savedSearchesProvider = createSavedSearchesProvider(createProviderParams);
    const sessionsProvider = createSessionsProvider(createProviderParams);
    const siteTaxonomyProvider = createSiteTaxonomyProvider(createProviderParams);
    const subscriptionsProvider = createSubscriptionsProvider(createProviderParams);
    const templateInfoProvider = createTemplateInfoProvider(createProviderParams);
    const urlMapProvider = createUrlMapProvider(createProviderParams);
    const usersProvider = createUsersProvider(createProviderParams);
    const userWalletsProvider = createUserWalletsProvider(createProviderParams);
    const workflowsProvider = createWorkflowsProvider(createProviderParams);
    const accessControlProvider = createAccessControlProvider(createProviderParams);

    // -----------------------------------------------
    // ---------------- DATA PROVIDER ----------------
    // -----------------------------------------------
    const raDataProvider = {
      getList: async (resource: string, params: any) => {
        try {
          const query = params?.query;
          const type = params?.type;
          const meta = params?.meta;
          const filter = params?.filter;
          const page = params?.pagination?.page;
          const perPage = params?.pagination?.perPage;
          const field = params?.sort?.field;
          const order = params?.sort?.order;

          let payload = {
            ...(query && { query: query }),
            ...(type && { type: type }),
            ...(page && { page: (page - 1) * perPage }),
            ...(perPage && { limit: perPage }),
            ...(order && { dir: order }),
            ...(field && { sort: field === 'id' ? 'uuid' : field }), // Use UUID for "sort by id" - note RA by default will set 'id' and 'asc' if not set.
            ...(meta && { meta: meta }),
            ...(filter && { ...filter }), // Spread filter object last into payload from RA InfListBase, etc.
          };

          const {
            baseResource,
            subResource,
            isSearch,
            additionalPayload,
            modifyPayload,
          } = getResourceDetails(resource);

          if (modifyPayload) {
            payload = modifyPayload(payload);
          }

          payload = { ...payload, ...additionalPayload };

          const url = `${apiUrl}/${baseResource}/${subResource}/${isSearch}?${fetchUtils.queryParameters(
            payload,
            stringifyOptions,
          )}`;

          const { json, status } = await httpClient({
            url: url,
            options: { method: 'GET' },
            authToken: authToken,
            defaultSite: defaultSite,
          });

          await assertResponseSuccess(json, status);

          const dataWithIds = json.data.items.map((item: any) => ({
            ...item,
            id: determineUniqueId(item, resource),
          }));

          const formattedResult = {
            data: dataWithIds,
            total: json.data.total,
          };

          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] getList() error:' + error?.toString());
          throw error;
        }
      },

      getOne: async (resource: string, params: any) => {
        let payload = {};
        try {
          const { baseResource, subResource, additionalPayload } =
            getResourceDetails(resource);

          payload = { ...payload, ...additionalPayload };

          const url = `${apiUrl}/${baseResource}/${subResource}/${
            params.id
          }/?${fetchUtils.queryParameters(payload, stringifyOptions)}`;

          const { json, status } = await httpClient({
            url: url,
            options: { method: 'GET' },
            authToken: authToken,
            defaultSite: defaultSite,
          });
          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: { ...json, id: json.data.uuid },
            id: json.data.uuid,
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] getOne() error:' + error?.toString());
          throw error;
        }
      },

      getMany: async (resource: string, params: any) => {
        const { baseResource, subResource } = getResourceDetails(resource);
        const fetchSingleId = async (id: string) => {
          const url = `${apiUrl}/${baseResource}/${subResource}/${id}/`;
          const { json } = await httpClient({
            url: url,
            options: { method: 'GET' },
            authToken: authToken,
            defaultSite: defaultSite,
          });
          return json.data;
        };

        try {
          const settledResponses = await Promise.allSettled(
            params.ids.map(fetchSingleId),
          );
          const successfulResponses = settledResponses
            .filter((response) => response.status === 'fulfilled')
            .map((response) => (response as PromiseFulfilledResult<any>).value);
          const failedResponses = settledResponses
            .filter((response) => response.status === 'rejected')
            .map((response) => (response as PromiseRejectedResult).reason);
          // Log failed responses or handle them appropriately.
          if (failedResponses.length > 0) {
            console.warn('dataProvider.getMany - Some fetches failed:', failedResponses);
          }
          // If all fetches failed, throw an error
          if (successfulResponses.length === 0) {
            throw new Error('dataProvider.getMany - All fetches failed.');
          }

          // Only return the successful responses, any failed responses won't be included.
          const results: GetManyResult = {
            data: successfulResponses.map((item: any) => ({
              ...item,
              id: item.uuid,
            })),
          };

          return results;
        } catch (error) {
          console.error('[dataProvider] getMany() error:' + error?.toString());
          throw error;
        }
      },

      getManyReference: async (resource: string, params: any) => {
        try {
          const { baseResource, subResource } = getResourceDetails(resource);
          const { page, perPage } = params.pagination;
          const { field, order } = params.sort;
          let payload = {
            ...params.filter,
            [params.target]: params.id,
            _page: page,
            _limit: perPage,
            _sort: field,
            _order: order,
          };

          const url = `${apiUrl}/${baseResource}/${subResource}/?${fetchUtils.queryParameters(
            payload,
            stringifyOptions,
          )}`;

          const { json, status } = await httpClient({
            url: url,
            options: { method: 'GET' },
            authToken: authToken,
            defaultSite: defaultSite,
          });

          await assertResponseSuccess(json, status);

          const formattedResult = json.data.items.map((item: any) => ({
            ...item,
            id: item.uuid,
          }));
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] getManyReference() error:' + error?.toString());
          throw error;
        }
      },

      create: async (resource: string, params: any) => {
        try {
          const { baseResource, subResource } = getResourceDetails(resource);

          const url = `${apiUrl}/${baseResource}/${subResource}/`;

          // Add the preview parameters to the data
          let payload = {
            ...params.data,
          };

          // Process related content and strip TOC
          const relatedPayload = await relatedContent(payload);
          const finalPayload = await stripTOC(relatedPayload);

          const { json, status } = await httpClient({
            url: url,
            options: {
              method: 'POST',
              body: JSON.stringify(finalPayload),
            },
            authToken: authToken,
            defaultSite: defaultSite,
          });

          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: { ...json, id: json.uuid },
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] create() error:' + error?.toString());
          throw error;
        }
      },

      update: async (resource: string, params: any) => {
        try {
          const { baseResource, subResource, additionalPayload } =
            getResourceDetails(resource);
          const url = `${apiUrl}/${baseResource}/${subResource}/${params.id}/`;

          // Add the preview parameters to the data
          let payload = {
            ...params.data,
          };

          payload = { ...payload, ...additionalPayload };

          // Process related content and strip TOC
          const relatedPayload = await relatedContent(payload);
          const finalPayload = await stripTOC(relatedPayload);

          const { json, status } = await httpClient({
            url: url,
            options: {
              method: 'PUT',
              body: JSON.stringify(finalPayload),
            },
            authToken: authToken,
            defaultSite: defaultSite,
          });

          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: { ...json, id: json.uuid },
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] update() error:' + error?.toString());
          throw error;
        }
      },

      updateMany: async (resource: string, params: any) => {
        const { baseResource, subResource } = getResourceDetails(resource);
        try {
          const query = {
            filter: JSON.stringify({ id: params.ids }),
          };

          const url = `${apiUrl}/${baseResource}//${subResource}/?${fetchUtils.queryParameters(
            query,
            stringifyOptions,
          )}`;

          const { json, status } = await httpClient({
            url: url,
            options: {
              method: 'PATCH',
              body: JSON.stringify(params.data),
            },
            authToken: authToken,
            defaultSite: defaultSite,
          });

          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: json,
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] updateMany() error:' + error?.toString());
          throw error;
        }
      },

      delete: async (resource: string, params: any) => {
        const { baseResource, subResource } = getResourceDetails(resource);
        try {
          const url = `${apiUrl}/${baseResource}/${subResource}/${params.id}/`;

          const { json, status } = await httpClient({
            url: url,
            options: {
              method: 'DELETE',
            },
            authToken: authToken,
            defaultSite: defaultSite,
          });

          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: json,
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] delete() error:' + error?.toString());
          throw error;
        }
      },

      deleteMany: async (resource: string, params: any) => {
        const { baseResource, subResource } = getResourceDetails(resource);

        try {
          const url = `${apiUrl}/${baseResource}/${subResource}/delete/`;
          const deleteObject = { uuid: params.ids };

          const { json, status } = await httpClient({
            url: url,
            options: {
              method: 'POST',
              body: JSON.stringify(deleteObject),
            },
            authToken: authToken,
            defaultSite: defaultSite,
          });

          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: json,
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] deleteMany() error:' + error?.toString());
          throw error;
        }
      },
    };

    // -----------------------------------------------
    // ---------------- AUTH PROVIDER ----------------
    // -----------------------------------------------
    const authProvider: AuthProvider = {
      // https://marmelab.com/react-admin/AuthProviderWriting.html
      // Note: CosmosAuthEnvContext / WithAuthEnvChecksProvider has effects which
      // handles redirecting to login page when not authenticated.
      // The 'login' here is likely unused, I didnt see it called anywhere outside our auth contexts.
      // however the react-admin type for AuthProvider requires it
      // Note some functions we use a lot like useDataProvider or useGetIdentity will
      // invoke checkAuth/checkError before making the requests, but if they return error
      // react admin will implicitly call logout() for us, which we may not want.

      login: async (params) => {
        await authLogin();
      },

      logout: async (params) => {
        await authLogout();
      },

      checkAuth: async (params) => {
        // Intuitively we would want to show the reauth dialog here
        // but noticed that during a manual logout this fn is called by
        // some other part of react admin and can cause a flash of the reauth dialog
        // while the browser is redirecting to the login page.
        // So for the dialog/auth states, we'll handle that in the WithAuthEnvChecksProvider.

        if (!auth.isAuthenticated) {
          console.warn('[authProvider/checkAuth] Deauthed or no user.');
        }

        // Just resolve to not have RA call logout()
        return Promise.resolve();
      },

      checkError: async (error: any) => {
        // If you were on some page that has background refetches on a set interval
        // like a dashboard, we don't want the checkError to keep re opening the
        // reauth dialog every time the dashboard refetches and errors in the background.
        // queries should add a check for auth.isAuthenticated AuthN or AuthZ in the enabled prop and deps

        // For now this is workaround in case we forget to disable the refetches
        // since the user wont be able to interact with anything while the dialog is open.
        const reauthDialogShowing = Boolean(reauthDialog);
        if (reauthDialogShowing) {
          return Promise.resolve();
        }

        // Else if its not showing then we need to check and determine if we should show it.
        // This one will check for 401/403 status codes or deauthed user and show the dialog
        // and seems to only be invoked when the dataProvider is actually making a request.
        const deauthed = !auth.isAuthenticated;
        const getStatus = error?.status || error?.response?.status; // Request status code...
        const unauthorizedStatus = getStatus === 401 || getStatus === 403;
        const deauthOrUnauthorized = deauthed || unauthorizedStatus;

        if (deauthOrUnauthorized) {
          console.error('[authProvider/checkError] Deauthed / no user / 401 or 403.');
          setReauthDialog(reauthDialogStateExpired); // Show the reauth dialog.
        }

        // Resolve to not have RA call logout()
        return Promise.resolve();
      },

      getIdentity: async (): Promise<UserIdentity> => {
        try {
          if (!auth.user) {
            throw new Error('[authProvider/getIdentity] User not found.');
          }
          if (!auth.isAuthenticated) {
            throw new Error('[authProvider/getIdentity] User unauthenticated.');
          }
          if (!auth.user.profile.email) {
            throw new Error('[authProvider/getIdentity] Email not found.');
          }
          const gravatarIconUrl = gravatarUrl(auth.user.profile.email, {
            size: 64,
            default: 'mm',
          });
          return Promise.resolve({
            id: String(auth.user.profile.email),
            avatar: gravatarIconUrl,
            uuid: auth?.user?.profile?.sub ?? '',
          });
        } catch (error) {
          console.error('[authProvider/getIdentity] error :' + error?.toString());
          return Promise.reject(error);
        }
      },

      getPermissions: async (params) => {
        /* Stubbed in to satisfy types - see notes */
        return Promise.resolve();
      },

      /**
       * Other Optional Methods: getPermissions, canAccess, handleCallback,
       * getPermissions - React-admin has built-in Permissions, currently unused.
       * canAccess - React-admin has built-in Access Control, currently unused.
       * handleCallback - RA Auth, we disabled on <Admin> as we need to handle this in our own
       * auth Context before the <Admin> component even mounts due to the "domain" URL to determine active site.
       * https://marmelab.com/react-admin/AuthProviderWriting.html#authprovider-interface-overview
       */
    };

    return {
      dataProvider: {
        ...raDataProvider,
        ...accessControlProvider,
        ...artificalIntelligenceProvider,
        ...blockLayoutProvider,
        ...blockLibraryProvider,
        ...dashboardsProvider,
        ...editorialProvider,
        ...experimentsProvider,
        ...previewsProvider,
        ...savedSearchesProvider,
        ...sessionsProvider,
        ...siteTaxonomyProvider,
        ...subscriptionsProvider,
        ...templateInfoProvider,
        ...urlMapProvider,
        ...usersProvider,
        ...userWalletsProvider,
        ...workflowsProvider,
      },
      authProvider: authProvider,
    };
  }, [
    auth,
    apiUrl,
    authToken,
    defaultSite,
    queryClient,
    envState,
    location,
    reauthDialog,
    setReauthDialog,
    authLogin,
    authLogout,
  ]);

  if (!contextValue) {
    return <BloxLoadingSplash message="Building Providers..." />;
  }

  return (
    <CosmosRAProvidersContext.Provider value={contextValue}>
      {children}
    </CosmosRAProvidersContext.Provider>
  );
};

/**
 * File Notes:
 *
 * --- Message ---
 * (pre-hooks/component version) - RA Correspondence on withLifecycleCallbacks + custom methods in dataprovider:
 *
 * Response:
 * withLifecycleCallbacks seems to only accept a basic dataProvider
 * (in the sense of without any custom methods) and returns a basic dataProvider as well.
 * However this is just a limitation of the TypeScript type currently in use, in fact you can
 * also provide an augmented dataProvider (including custom methods) and it will work too.
 *
 * withLifecycleCallbacks is only meant to override standard react-admin methods (getOne, getList, ...) so we
 * figured typing the base dataProvider as DataProvider was enough, but your question makes me
 * realize we could add support for generic types there.
 *
 * Anyways, TLDR is, the code would work both ways (regardless of what TypeScript says),
 * and the approach you described is perfectly valid.
 * Cheers, Jean-Baptiste, React Admin Support Team
 *
 *
 * --- Message ---
 * RA Correspondence on adding custom lifecycle callbacks to our custom methods, if ever needed.
 *
 * To achieve that, you can take a look at withLifecycleCallbacks() definition
 * in ra-core/src/dataProvider/withLifecycleCallbacks.ts and take inspiration from the ResourceCallbacks[] type.
 *
 * --- Here is an implementation example ---
 * const baseDataProvider = simpleRestProvider("http://path.to.my.api/");
 * const transformData = (data: any[]) => {
 *   return data; // do something with data
 * };
 *
 * interface CustomResourceCallbacks extends ResourceCallbacks {
 *   binaryUpload?: (params: any) => Promise<any>;
 *   binaryConvert?: (params: any) => Promise<any>;
 * }
 *
 * // override withLifecycleCallbacks definition
 * const customWithLifecycleCallbacks = (
 *   dataProvider: DataProvider,
 *   handlers: CustomResourceCallbacks[]
 * ) => withLifecycleCallbacks(dataProvider, handlers);
 *
 * export const dataProvider = customWithLifecycleCallbacks(baseDataProvider, [
 *   {
 *     resource: "posts",
 *     afterGetManyReference: async (result) => {
 *       // existing callback
 *       const data = transformData(result.data);
 *       return { data, total: result.total };
 *     },
 *     afterGetList: async (result) => {
 *       // existing callback
 *       const data = transformData(result.data);
 *       return { data, total: result.total };
 *     },
 *     binaryUpload: async (params) => {
 *       // your custom callback
 *       const data = transformData(params);
 *       return Promise.resolve({ data });
 *     },
 *     binaryConvert: async (params) => {
 *       // your custom callback
 *       const data = transformData(params);
 *       return Promise.resolve({ data });
 *     },
 *   },
 * ]);
 *
 */

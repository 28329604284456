import { assertResponseSuccess } from 'providers/functions';
import { CosmosCreateProvidersParams, httpClient } from '../CosmosRAProvidersContext';

import {
  GetAssetPreviewResult,
  GetBlockPreviewResult,
  GetPagePreviewResult,
} from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ PREVIEWS PROVIDER ----------------
// -----------------------------------------------
export const createPreviewsProvider = ({
  apiUrl,
  authToken,
  defaultSite,
  stringifyOptions,
  queryClient,
}: CosmosCreateProvidersParams) => ({
  // Update the function to fetch the asset preview
  getAssetPreview: async (id: string, draft: boolean): Promise<GetAssetPreviewResult> => {
    try {
      const url = `${apiUrl}/core/preview/asset/editorial/${id}/${
        draft ? '?draft=true' : ''
      }/`;

      const { status, body, json } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);
      const result: GetAssetPreviewResult = {
        status: 'success',
        data: { [id]: { body: body } }, // Key-value pair using the id as the key
      };
      return result;
    } catch (error) {
      console.error('[dataProvider] getAssetPreview() error:' + error?.toString());
      throw error;
    }
  },

  // Get a preview rendering of a block instance
  getBlockPreview: async (
    map_id: string,
    block_id: string,
  ): Promise<GetBlockPreviewResult> => {
    try {
      const urlWithParams = `${apiUrl}/core/preview/block/${map_id}/${block_id}/`;

      const { status, body, json } = await httpClient({
        url: urlWithParams,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);
      const result: GetBlockPreviewResult = {
        status: 'success',
        data: { body: body },
      };
      return result;
    } catch (error) {
      console.error('[dataProvider] getBlockPreview() error:' + error?.toString());
      throw error;
    }
  },

  // Get a preview rendering of a front-end URl
  getPagePreview: async (
    url: string,
    urlmap?: string,
    date?: string,
    live?: boolean,
  ): Promise<GetPagePreviewResult> => {
    try {
      // Ensure url starts and ends with a slash
      if (!url.startsWith('/')) {
        url = `/${url}`;
      }
      if (!url.endsWith('/')) {
        url = `${url}/`;
      }

      // If date is not provided, use the current date to ensure the preview is not from cache
      if (!date) {
        const now = new Date();
        date = now.toISOString();
      }

      const urlParams = new URLSearchParams();
      urlParams.append('url', url);
      if (urlmap) urlParams.append('urlmap', urlmap);
      if (date) urlParams.append('date', date);
      if (live !== undefined) urlParams.append('live', live.toString());

      const urlWithParams = `${apiUrl}/core/preview/page/?${urlParams.toString()}`;

      const { status, body, json } = await httpClient({
        url: urlWithParams,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);
      const result: GetPagePreviewResult = {
        status: 'success',
        data: { body: body },
      };
      return result;
    } catch (error) {
      console.error('[dataProvider] getPagePreview() error:' + error?.toString());
      throw error;
    }
  },
});

import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AuthContextProps, useAuth } from 'react-oidc-context';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import BloxLoadingSplash from '../../components/generic/BloxLoadingSplash';
import ErrorAlerts, { ErrorDetails } from '../../components/generic/ErrorAlerts';
import NoCosmosPermissions from '../../components/generic/NoCosmosPermissions';
import { capitalizeFirstLetter } from '../../utility/functions';
import {
  ApiErrorResponse,
  GetUserMeFullItem,
  SessionConfigResult,
  UserAccountGroups,
} from '../../utility/types/dataProvider';
import { EnvData, useCosmosAuthEnv } from './CosmosAuthEnvContext';
import { User } from 'oidc-client-ts';
import { createSessionsProvider } from './extensions/Sessions';
import {
  CosmosCreateProvidersParams,
  stringifyOptions,
} from './CosmosRAProvidersContext';
import { createUsersProvider } from './extensions/Users';

//----------------------------------------------------------------------
// Types and Consts
//----------------------------------------------------------------------

export interface CosmosContextType {
  changeActiveSiteAsync: (newDefaultSite: string) => Promise<void>;
  clearInvalidDomain: () => void;
  /** The currently 'active' site/domain. */
  defaultSite: string;
  invalidDomain: string;
  sessionConfig?: SessionConfigResult;
  userInfo?: GetUserMeFullItem; // get /user/me/
  userGroups?: UserAccountGroups;
  userPermissions?: AppPermissions;
  apiUrl?: string; // derived from envState
  authToken?: string; // derived from auth
  authInfo?: User; // re-exported for convenience via useAuth?.user
}

type EditorialPermissions = {
  full_access: boolean;
  batch_edit_search: boolean;
  change_publish_status: boolean;
  create_assets: boolean;
  delete_assets: boolean;
  modify_assets: boolean;
  change_process: boolean;
  change_workflow: boolean;
};

type UserPermissions = {
  full_access: boolean;
  manage_groups: boolean;
  manage_profiles: boolean;
};

// Will grow as more permissions are added
type AppPermissions = {
  editorial_asset: EditorialPermissions;
  user: UserPermissions;
};

// Initial state - certain other values in context are derived.
const baseCosmosState: CosmosStateType = {
  ready: false, // prevent no permissions flicker
  defaultSite: '',
  invalidDomain: '',
  sessionConfig: undefined,
  userInfo: undefined,
  userGroups: undefined,
  userPermissions: undefined,
};

interface CosmosStateType {
  ready: boolean;
  defaultSite: string;
  invalidDomain: string;
  sessionConfig?: SessionConfigResult;
  userInfo?: GetUserMeFullItem;
  userGroups?: UserAccountGroups;
  userPermissions?: AppPermissions;
}

// Custom return type for the initialSessionConfig useQuery.
type InitialStateUpdate = Pick<
  CosmosStateType,
  'defaultSite' | 'invalidDomain' | 'sessionConfig'
>;

interface InitCosmosQueryFnParams {
  authBloxCMSDefaultSite: string;
  addressBarDomain: string;
  apiUrl: CosmosCreateProvidersParams['apiUrl'];
  authToken: CosmosCreateProvidersParams['authToken'];
  stringifyOptions: CosmosCreateProvidersParams['stringifyOptions'];
  queryClient: CosmosCreateProvidersParams['queryClient'];
}

//----------------------------------------------------------------------
// Helper Functions
//----------------------------------------------------------------------
function getAuthTokenStr(auth: AuthContextProps) {
  const tokenType = String(auth?.user?.token_type);
  const accessToken = auth?.user?.access_token;
  return `${capitalizeFirstLetter(tokenType)} ${accessToken}`;
}

function getApiUrl(envState?: EnvData) {
  return `https://${envState?.API_SERVER_URL}/rest/admin`;
}

function getDefaultOidcSite(auth: AuthContextProps) {
  return String(auth?.user?.profile['bloxcms.default_site']).toLowerCase();
}

function getAddressBarDomain() {
  return window.location.pathname.split('/')[1]?.toLowerCase();
}

function checkCosmosAccess(
  sessionConfig?: SessionConfigResult,
  userInfo?: GetUserMeFullItem,
) {
  if (!sessionConfig || !userInfo) {
    return false; // No session or user info, can't determine access yet.
  }
  const hasCosmos = Boolean(sessionConfig?.software_privileges?.core?.includes('cosmos'));
  const isStaffUser = Boolean(userInfo?.account_type === 'staff');
  // Staff always allowed, otherwise must have cosmos privileges.
  return isStaffUser || hasCosmos;
}

function getPermissions(allPermissions: string[]): AppPermissions {
  const configPermissions: AppPermissions = {
    editorial_asset: {
      full_access: false,
      batch_edit_search: false,
      change_publish_status: false,
      create_assets: false,
      delete_assets: false,
      modify_assets: false,
      change_process: false,
      change_workflow: false,
    },
    user: {
      full_access: false,
      manage_groups: false,
      manage_profiles: false,
    },
  };

  // Check if "full-access" is in the allPermissions array
  if (allPermissions.includes('full-access')) {
    // Iterate over each permission type and set all permissions to true
    Object.keys(configPermissions).forEach((permissionType) => {
      // Get the permissions object for the current permission type
      const permissionsObject = configPermissions[permissionType as keyof AppPermissions];
      // Set all permissions for the current type to true
      Object.keys(permissionsObject).forEach((permissionKey) => {
        permissionsObject[permissionKey as keyof typeof permissionsObject] = true;
      });
    });
    return configPermissions;
  }

  // If not "full-access" iterate over each permission and
  // set true if found in the allPermissions array
  allPermissions.forEach((permission) => {
    const [application, , ...details] = permission.split('/');
    const permissionApp = application.replace(/-/g, '_');

    // If the application is in our config set permissions
    if (permissionApp === 'editorial_asset' || permissionApp === 'user') {
      // Check for full access
      let fullAccess = false;
      if (details.includes('Full access')) {
        fullAccess = true;
      } else {
        // Create permissions array for check
        const simplifiedPermission = details.map((detail) => {
          return detail.toLowerCase().replace(/\s+/g, '_');
        });
        // Set the permission to true if it's found in the details
        simplifiedPermission.forEach((permission) => {
          if (permission in configPermissions[permissionApp]) {
            configPermissions[permissionApp][
              permission as keyof (typeof configPermissions)[typeof permissionApp]
            ] = true;
          }
        });
      }

      // If full access is found, set all permissions of the permission type to true
      if (fullAccess) {
        Object.keys(configPermissions[permissionApp]).forEach((key) => {
          if (key !== 'fullAccess') {
            configPermissions[permissionApp][
              key as keyof (typeof configPermissions)[typeof permissionApp]
            ] = true;
          }
        });
        configPermissions[permissionApp].full_access = true;
      }
    }
  });

  return configPermissions;
}

//----------------------------------------------------------------------
// Query Functions
//----------------------------------------------------------------------
const tryGetInitialSessionConfig = async ({
  apiUrl,
  authToken,
  defaultSite,
  stringifyOptions,
  queryClient,
}: CosmosCreateProvidersParams) => {
  if (!apiUrl || !authToken || !defaultSite || !stringifyOptions || !queryClient) {
    throw new Error('[tryGetInitialSessionConfig] Missing required parameters!');
  }
  try {
    const { getSessionConfig } = createSessionsProvider({
      apiUrl,
      authToken,
      defaultSite,
      stringifyOptions,
      queryClient,
    });
    const sessionConfigResult = await getSessionConfig();
    return sessionConfigResult;
  } catch (error) {
    console.error('[tryGetInitialSessionConfig] error:', error);
    throw error;
  }
};

// Intialize Cosmos - determine active domain + /core/session/config/
const initCosmosQueryFn = async ({
  authBloxCMSDefaultSite,
  addressBarDomain,
  apiUrl,
  authToken,
  stringifyOptions,
  queryClient,
}: InitCosmosQueryFnParams): Promise<InitialStateUpdate> => {
  try {
    // Edge case: localhost:3000/ (no domain in address bar), throw to OIDC fallback catch block.
    if (!addressBarDomain) {
      throw new Error('URL domain is undefined, falling back to OIDC domain.');
    }

    // Try using the address bar domain as defaultSite first.
    const sessionConfigResult = await tryGetInitialSessionConfig({
      apiUrl,
      authToken,
      defaultSite: addressBarDomain,
      stringifyOptions,
      queryClient,
    });

    // didn't throw, domain in the addressBar was valid
    const stateUpdate: InitialStateUpdate = {
      sessionConfig: sessionConfigResult,
      defaultSite: addressBarDomain,
      invalidDomain: '',
    };

    // Return result for effect state update.
    return stateUpdate;
  } catch (error) {
    // Can't proceed without some default site to fall back to.
    if (!authBloxCMSDefaultSite) {
      throw new Error('No fallback. Auth bloxcms.default_site is undefined/missing.');
    }
    // Retry to initialize the app with the oidc tokens default site instead.
    // Error is likely due to the domain from address bar invalid.
    try {
      const sessionConfigResult = await tryGetInitialSessionConfig({
        apiUrl,
        authToken,
        defaultSite: authBloxCMSDefaultSite,
        stringifyOptions,
        queryClient,
      });

      // didn't throw, domain in the oidc worked
      const stateUpdate: InitialStateUpdate = {
        sessionConfig: sessionConfigResult,
        defaultSite: authBloxCMSDefaultSite, // fallback to their oidc default site.
        invalidDomain: addressBarDomain, // whatever was in the address bar as domain was invalid
      };

      return stateUpdate;
    } catch (error) {
      // If caught error, likely user lacks cosmos permissions or failed to fetch, etc.
      console.error('[initCosmosQueryFn] error:', error);
      throw error;
    }
  }
};

//----------------------------------------------------------------------
// CosmosContext Provider and Hooks
//----------------------------------------------------------------------
export const CosmosContext = createContext<CosmosContextType | undefined>(undefined);

export const useCosmos = () => {
  const context = useContext(CosmosContext);
  if (!context) {
    throw new Error('useCosmos must be used within a CosmosProvider!');
  }
  return context;
};

export const CosmosProvider = ({ children }: PropsWithChildren) => {
  // hooks and states
  const auth = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { envState } = useCosmosAuthEnv();
  const [cosmosState, setCosmosState] = useState<CosmosStateType>(baseCosmosState);

  // derive consts
  const addressBarDomain = getAddressBarDomain();
  const authBloxCMSDefaultSite = getDefaultOidcSite(auth);
  const apiUrl = getApiUrl(envState);
  const authToken = getAuthTokenStr(auth);

  // check access
  const canAccessCosmos = useMemo(() => {
    return checkCosmosAccess(cosmosState?.sessionConfig, cosmosState?.userInfo);
  }, [cosmosState?.sessionConfig, cosmosState?.userInfo]);

  // initCosmosQuery - fetches and initializes state once auth is valid
  const initQueryEnabled = Boolean(
    auth?.isAuthenticated &&
      !auth.isLoading &&
      authBloxCMSDefaultSite &&
      apiUrl &&
      authToken &&
      queryClient &&
      stringifyOptions,
  );

  const {
    data: initCosmosQueryData,
    error: initCosmosQueryError,
    status: initCosmosQueryStatus,
    refetch: initCosmosQueryRefetch,
  } = useQuery<InitialStateUpdate, ApiErrorResponse>({
    queryKey: [
      'CosmosProvider',
      'initCosmosQuery',
      'getSessionConfig',
      addressBarDomain,
      authBloxCMSDefaultSite,
    ],
    queryFn: async () => {
      const result = await initCosmosQueryFn({
        authBloxCMSDefaultSite: authBloxCMSDefaultSite,
        addressBarDomain: addressBarDomain,
        apiUrl: apiUrl,
        authToken: authToken,
        stringifyOptions: stringifyOptions,
        queryClient: queryClient,
      });
      return result;
    },
    enabled: initQueryEnabled,
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Sync to state initCosmosQuery once it's available or changes.
  useEffect(() => {
    if (initCosmosQueryData) {
      // Parse permissions from the sessionConfig data
      const parsedPermissions = getPermissions(
        initCosmosQueryData?.sessionConfig?.permissions || [],
      );
      // Update the state with the the new/initial data fetched and processed.
      setCosmosState((prevValues) => ({
        ...prevValues,
        ...initCosmosQueryData,
        userPermissions: parsedPermissions,
      }));
    }
  }, [initCosmosQueryData, apiUrl, authToken, queryClient]);

  // Enable post-init queries once the defaultSite is determined via initCosmosQuery.
  const enablePostInitQueries = Boolean(
    cosmosState?.defaultSite && initCosmosQueryStatus === 'success',
  );

  // Get current user info from /user/me/
  const {
    data: userMeQueryData,
    error: userMeQueryError,
    status: userMeQueryStatus,
  } = useQuery<GetUserMeFullItem, ApiErrorResponse>({
    queryKey: ['CosmosProvider', 'getUserMe', cosmosState?.defaultSite],
    queryFn: async () => {
      if (!cosmosState?.defaultSite) {
        throw new Error('[CosmosContext - getUserMe] defaultSite is missing!');
      }
      const { getUserMe } = createUsersProvider({
        apiUrl: apiUrl,
        authToken: authToken,
        defaultSite: cosmosState?.defaultSite,
        stringifyOptions: stringifyOptions,
        queryClient: queryClient,
      });
      const userInfo = await getUserMe();
      const userData: GetUserMeFullItem = {
        ...userInfo?.data,
      };
      return userData;
    },
    enabled: enablePostInitQueries,
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Sync to state userMeData once it's available or changes.
  useEffect(() => {
    if (userMeQueryData) {
      setCosmosState((prevValues) => ({
        ...prevValues,
        userInfo: userMeQueryData,
      }));
    }
  }, [userMeQueryData]);

  // Get current user groups from /user/me/groups
  const {
    data: userGroupsQueryData,
    error: userGroupsQueryError,
    status: userGroupsQueryStatus,
  } = useQuery<UserAccountGroups, ApiErrorResponse>({
    queryKey: ['CosmosProvider', 'getActiveUserGroups', cosmosState?.defaultSite],
    queryFn: async () => {
      if (!cosmosState?.defaultSite) {
        throw new Error('[CosmosContext - getActiveUserGroups] defaultSite is missing!');
      }
      const { getActiveUserGroups } = createUsersProvider({
        apiUrl: apiUrl,
        authToken: authToken,
        defaultSite: cosmosState?.defaultSite,
        stringifyOptions: stringifyOptions,
        queryClient: queryClient,
      });
      const userGroups = await getActiveUserGroups();
      return userGroups;
    },
    enabled: enablePostInitQueries,
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  // Sync to state userGroupsQueryData once it's available or changes.
  useEffect(() => {
    if (userGroupsQueryData) {
      setCosmosState((prevValues) => ({
        ...prevValues,
        userGroups: userGroupsQueryData,
      }));
    }
  }, [userGroupsQueryData]);

  // Mutation - Swap to the domain string provided.
  const { mutateAsync: changeActiveSiteAsync } = useMutation(
    async (newActiveSite: string) => {
      // On swapped site - Clear/reset/invalidate any errors, queries, states as we are about to refetch / reinitialize.
      queryClient.clear();
      await queryClient.invalidateQueries();
      await queryClient.resetQueries();
      // Reset the state values.
      setCosmosState(baseCosmosState);
      // Navigate to the new site domain before refetch since the address bar domain is used in the inital query.
      navigate(`/${newActiveSite.toLowerCase()}`, { replace: true });
      // Refetch initializeCosmosQuery.
      await initCosmosQueryRefetch();
    },
  );

  // Context value
  const contextValue = useMemo(() => {
    return {
      defaultSite: cosmosState?.defaultSite, // Note: defaultSite is the 'active' domain in the address bar.
      invalidDomain: cosmosState?.invalidDomain,
      sessionConfig: cosmosState?.sessionConfig,
      userInfo: cosmosState?.userInfo,
      userGroups: cosmosState?.userGroups,
      userPermissions: cosmosState?.userPermissions,
      apiUrl: apiUrl,
      authToken: authToken,
      authInfo: auth?.user || undefined,
      changeActiveSiteAsync: changeActiveSiteAsync,
      clearInvalidDomain: () => {
        setCosmosState((prevValues) => ({
          ...prevValues,
          invalidDomain: '',
        }));
      },
    };
  }, [cosmosState, auth, apiUrl, authToken, changeActiveSiteAsync]);

  // --------------- Rendering / Returns ---------------

  useEffect(() => {
    const allReady = Boolean(
      initCosmosQueryStatus === 'success' &&
        userMeQueryStatus === 'success' &&
        userGroupsQueryStatus === 'success',
    );
    if (allReady) {
      setCosmosState((prevValues) => ({
        ...prevValues,
        ready: true, // ready to render, prevent no permissions flicker
      }));
    }
  }, [initCosmosQueryStatus, userMeQueryStatus, userGroupsQueryStatus]);

  const anyErrors = Boolean(
    initCosmosQueryError || userMeQueryError || userGroupsQueryError,
  );

  if (anyErrors) {
    const errorResults: ErrorDetails[] = [
      initCosmosQueryError,
      userMeQueryError,
      userGroupsQueryError,
    ]
      .filter(Boolean)
      .map((error) => {
        return {
          message: error?.message,
          status: error?.status,
          error: error,
        };
      });

    console.error('[CosmosProvider Errors]:', errorResults);

    return (
      <ErrorAlerts
        severity="error"
        buttonText="Reload"
        errors={errorResults}
        buttonOnClick={() => {
          // Best to try a hard reset if things fail this early for good measure.
          window?.location?.reload();
        }}
      />
    );
  }

  if (!cosmosState.ready) {
    return <BloxLoadingSplash message="Please wait..." />;
  }

  if (!canAccessCosmos) {
    return <NoCosmosPermissions />;
  }

  return <CosmosContext.Provider value={contextValue}>{children}</CosmosContext.Provider>;
};

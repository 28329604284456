import { assertResponseSuccess } from 'providers/functions';
import { CosmosCreateProvidersParams, httpClient } from '../CosmosRAProvidersContext';
import { fetchUtils } from 'react-admin';

import {
  DefaultSiteResult,
  GetSitesParams,
  GetSitesResult,
  SessionConfigResult,
  SiteItem,
} from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ SESSION PROVIDER -----------------
// -----------------------------------------------
export const createSessionsProvider = ({
  apiUrl,
  authToken,
  defaultSite,
  stringifyOptions,
  queryClient,
}: CosmosCreateProvidersParams) => ({
  // Get user's default site
  getDefaultSite: async (): Promise<string | null> => {
    try {
      const url = `${apiUrl}/core/session/sites/default/`;

      const { json, status } = await httpClient({
        url: url,
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: DefaultSiteResult = json;
      return result.data.domain; // Return default site from API (could be success, w/ domain: null if unset)
    } catch (error) {
      console.error('[dataProvider] getDefaultSite() error:' + error?.toString());
      throw error;
    }
  },

  // Set user's default site
  setDefaultSite: async (domain: string): Promise<any> => {
    try {
      const url = `${apiUrl}/core/session/sites/default/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'POST',
          body: JSON.stringify({ domain: domain }),
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      // Return the status and data. Should be Status 204 if successful.
      const result = {
        status: status,
        data: json?.data,
      };

      return result;
    } catch (error) {
      console.error('[dataProvider] setDefaultSite() error:' + error?.toString());
      throw error;
    }
  },

  // Get user's session config
  getSessionConfig: async (): Promise<SessionConfigResult> => {
    try {
      const url = `${apiUrl}/core/session/config/`;

      const { json, status } = await httpClient({
        url: url,
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const sessionConfig: SessionConfigResult = json.data;
      return sessionConfig;
    } catch (error) {
      console.error('[dataProvider] getSessionConfig() error:' + error?.toString());
      throw error;
    }
  },

  // Get site access list
  getSites: async (params: GetSitesParams): Promise<GetSitesResult> => {
    try {
      const payload = {
        ...(params.query && { query: params.query }),
        ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }),
        ...(params.page && { page: params.page }),
      };

      const url = `${apiUrl}/core/session/sites/?${fetchUtils.queryParameters(
        payload,
        stringifyOptions,
      )}`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetSitesResult = {
        status: json.status,
        data: {
          total: json.data.total,
          next: json.data.next,
          prev: json.data.prev,
          items: json.data.items.map((item: SiteItem) => ({ ...item })),
        },
      };
      return result;
    } catch (error) {
      console.error('[dataProvider] getSites() error:' + error?.toString());
      throw error;
    }
  },
});
